import React from 'react';
import {  
    Box
} from 'grommet';
import { motion } from 'framer-motion';

function CustomImage(props: any) {

    const [imageLoading, setImageLoading] = React.useState(true);
    const [pulsing, setPulsing] = React.useState(true);

    const transition = {
        height: { delay: 0, duration: 0.4 },
        opacity: { delay: 0.5, duration: 0.4 }
    }

    const imageLoaded = () => {
        setImageLoading(false);
        setTimeout(() => setPulsing(false), 600);
    };

    return(
        <Box width="large" pad="medium">
            <div
                className={`${pulsing ? "pulse" : ""} loadable`}
                style={{ width: "95%", background: "#f5f6f8" }}
            >
                <motion.img
                    initial={{ 
                        //height: "16rem", 
                        opacity: 0 
                    }}
                    whileInView={{ 
                        opacity: imageLoading ? 0 : 1 
                    }}
                    // style={{ height: imageLoading ? "6rem" : "auto" }}
                    viewport={{ once: true }}
                    transition={transition}
                    onLoad={imageLoaded}
                    width="100%"
                    src={props.href}
                />
            </div>
        </Box>
    )
}

export default CustomImage;