import React from 'react';
import {  
    Box,
    Form,
    FormField,
    TextInput,
    Button,
    TextArea,
    Notification,
    Anchor
} from 'grommet';
import { useMediaQuery } from 'react-responsive';
import CustomCard from './shared/CustomCard';
import { constants } from '../types/constants';
import { motion } from 'framer-motion';
import * as Icons from 'grommet-icons';
import emailjs from '@emailjs/browser';
import { Bars } from 'react-loader-spinner';

function Section8() {

    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
    const isTablet = useMediaQuery({ query: `(max-width: 1024px)` });

    const [error, setError] = React.useState<string>();
    const [success, setSuccess] = React.useState<string>();
    const [loading, setLoading] = React. useState<Boolean>();

    function isValidEmail(email:string) {
        return /\S+@\S+\.\S+/.test(email);
    }

    const sendEmail = (value: any) => {
        //e.preventDefault();
        setLoading(true);
        emailjs.send("service_dhigzsv","template_8i6q9qg",{
            name: value.name,
            content: value.content,
            email: value.email,
        }, '2rPHXMs7JXNmCBZBf').then((result) => {
            console.info(result.text);
            setSuccess('Email sent successfully! We will contact you as soon as possible.');
            clearInputs();
            setLoading(false);
        }, (error) => {
            setLoading(false);
            console.error(error);
            setError('Email service is not working right now. Please try again later.');
        });
        clearInputs();
    };

    const clearInputs = () => {
        let nameObj:any = document.getElementById("name-input");
        let emailObj:any = document.getElementById("email-input");
        let contentObj:any = document.getElementById("content-input");

        if (nameObj.value != "") {
            nameObj.value = "";
        }
        
        if (emailObj.value !="") {
            emailObj.value = "";
        }

        if (contentObj.value !="") {
            contentObj.value = "";
        }
    }

    const handleSubmit = (value: any) =>{
        console.log(value)
        if(!isValidEmail(value.email)) {
            setError('Email is invalid, please try again.');
        } else if(!value.name || value.name === "") {
            setError('Name is empty, please try again.');
        } else if(!value.content || value.content === "") {
            setError('Content is empty, please try again.');
        } else {
            setError(undefined);
            sendEmail(value);
        }
    }

    const form = (
        <>
            <Form
                //value={(value:any)=>{console.log(value)}}
                //onChange={nextValue => setValue(nextValue)}
                //onReset={() => setValue({})}
                onSubmit={({ value }) => { handleSubmit(value) }}
            >
                {error &&
                    <Box justify='center' alignContent='center' style={{marginBottom:"20px"}}>
                        <Notification
                            title = {"¡Ups! Hemos encontrado un error al enviar."}
                            message= {error}
                            onClose={() => {setError(undefined);}}
                            icon={<Icons.CircleAlert />}
                            status='critical'
                        />
                    </Box>
                }
                {success &&
                    <Box justify='center' alignContent='center' style={{marginBottom:"20px"}}>
                        <Notification
                            title = {"¡Enhorabuena! La acción ha sido exitosa."}
                            message= {success}
                            onClose={() => {setSuccess(undefined);}}
                            icon={<Icons.StatusGood />}
                            status="normal"
                        />
                    </Box>
                }
                <FormField name="name" htmlFor="name-input" label={constants.S8_FORM_LABEL1}>
                    <TextInput id="name-input" name="name" />
                </FormField>
                <FormField name="email" htmlFor="email-input" label={constants.S8_FORM_LABEL2}>
                    <TextInput id="email-input" name="email" />
                </FormField>
                <FormField name="content" htmlFor="content-input" label={constants.S8_FORM_LABEL3}>
                    <TextArea id="content-input" name="content" />
                </FormField>
                {loading ?
                    <Box direction={"row"} justify='center' alignContent='center' gap="xsmall"  pad="medium">
                        <Bars
                            height="35"
                            width="100"
                            color="#576cbc"
                            ariaLabel="bars-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                        />
                    </Box>
                    :
                    <Box direction={"row"} justify='center' alignContent='center' gap="xsmall"  pad="medium">
                        <motion.span
                            whileHover={{ scale: 1.07 }}
                            whileTap={{ scale: 1.01 }}
                        >
                            <Button type="submit" primary label="Enviar" size={isMobile ? "small" : "medium"} />
                        </motion.span>
                        <motion.span
                            whileHover={{ scale: 1.07 }}
                            whileTap={{ scale: 1.01 }}
                        >
                            <Button type="reset" secondary label="Limpiar" size={isMobile ? "small" : "medium"} />
                        </motion.span>
                    </Box>
                }
            </Form>
        </>
    )

    return(
        <>
            <Box 
                direction={"row"}
                //background="white"
                background="#F5F7FA"
                pad={isMobile || isTablet ? "medium" : "xlarge"}
                animation={"fadeIn"}
                alignContent='center'
                justify='center'
                style={{
                    paddingLeft: `${!(isMobile || isTablet) ? '20%' : '5%'}`,
                    paddingRight: `${!(isMobile || isTablet) ? '20%' : '5%'}`,
                }}
            >

                <CustomCard
                    headerText = {constants.S8_FORM_TITLE}
                    contentText = {form}
                    //background = "#F5F7FA"
                    background = "white"
                    //fontColor="white"
                />

            </Box>
            
            {isMobile &&
                <Box 
                    direction={"row"}
                    //background="white"
                    background="#F5F7FA"
                    pad={isMobile || isTablet ? "medium" : "medium"}
                    animation={"fadeIn"}
                    alignContent='end'
                    justify='end'
                >
                    <Box
                        align={"baseline"}
                    >
                        <motion.span
                                whileHover={{ scale: 1.07 }}
                                whileTap={{ scale: 1.01 }}
                            >
                                <Button  icon={<Icons.Ascending size='xlarge' color='#0b2447'/>} href='#header' style={{cursor: 'pointer'}}/>
                        </motion.span>
                    </Box>
                </Box>
            }
        </>
    )
}

export default Section8;