import React from 'react';
import {  
    Box,
    Button,
    Text
} from 'grommet'
import { useMediaQuery } from 'react-responsive';
import { constants } from '../types/constants';
import { motion } from 'framer-motion'

function Section1() {

    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
    const isTablet = useMediaQuery({ query: `(max-width: 1024px)` });

    const backgroundImage = {
        color: "white",
        //dark: true,
        opacity: 0.95,
        position: "center",
        repeat: "no-repeat",
        size: "cover",
        image: `url(/assets/tech${isMobile || isTablet ? 'mobile' : ''}.gif)`,
        //clip: "text",
        //rotate: 45
    }

    return(
        <Box 
            //align="center" 
            //pad="large"
            background={backgroundImage}
        >

            <Box 
                align="center"
                style={{
                    paddingTop: `${!(isMobile || isTablet) ? '5%' : ''}`,
                    paddingBottom: `${!(isMobile || isTablet) ? '5%' : '2%'}`,
                    paddingLeft: `${!(isMobile || isTablet) ? '20%' : '5%'}`,
                    paddingRight: `${!(isMobile || isTablet) ? '20%' : '5%'}`
                }}
                animation={"fadeIn"}
            >
                <Text
                    alignSelf="center"
                    textAlign="center"
                    size="calc(20px + (50 - 20) * ((100vw - 300px) / (1600 - 300)))"
                    margin="medium"
                    weight="bold"
                    color={"white"}
                >
                    {constants.S1_TITLE}
                </Text>

                <Text
                    alignSelf="center"
                    textAlign="center"
                    size="calc(15px + (20 - 15) * ((100vw - 300px) / (1600 - 300)))"
                    color={"white"}
                >
                    {constants.S1_SUBTITLE}
                </Text>

                <Box 
                    direction="row"
                    justify='center'
                    alignContent='center'
                    round="small"
                    style={{
                        paddingTop: '6%',
                    }}
                >
                    
                    <motion.span
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 1.01 }}
                    >
                        <Button 
                            label="Empresas"
                            size={isMobile ? "small" : "large"}
                            margin="xsmall"
                            className='button-no-shadow'
                            primary
                            onClick={()=>{
                                window.location.href = '#empresas';
                            }}
                        />
                    </motion.span>
                    
                    <motion.span
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 1.01 }}
                    >
                        <Button 
                            label="Profesionales" 
                            size={isMobile ? "small" : "large"}
                            margin="xsmall"
                            secondary
                            color="white"
                            className='button-no-shadow'
                            style={{
                                color: 'white',
                            }}
                            onClick={()=>{
                                window.location.href = '#profesionales';
                            }}
                        />
                    </motion.span>
                </Box>

            </Box>
        </Box>
    )
}

export default Section1;