import React from 'react';
import {  
    Box,
    Image,
    Text
} from 'grommet'
import { useMediaQuery } from 'react-responsive';
import { constants } from '../types/constants';
import CustomImage from './shared/CustomImage';

function Section4() {

    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
    const isTablet = useMediaQuery({ query: `(max-width: 1024px)` });

    return(
        <>
            <Box 
                direction={isMobile || isTablet ? undefined : "row"}
                background="white"
                pad={isMobile || isTablet ? "small" : "large"}
                animation={"fadeIn"}
            >

                <CustomImage 
                    href="assets/working.webp"
                />

                <Box 
                    width="large" 
                    pad="small" 
                    alignSelf="center"
                >
                    <Text
                        //textAlign={isMobile || isTablet ? "center" : "end"}
                        textAlign={"center"}
                        size="calc(20px + (34 - 20) * ((100vw - 300px) / (1600 - 300)))"
                        margin="medium"
                        weight="bold"
                        color={"#43484d"}
                    >
                        {constants.S4_TITLE}
                    </Text>

                    <Text
                        //textAlign={isMobile || isTablet ? "center" : "end"}
                        textAlign={"center"}
                        color={"#43484d"}
                    >
                        {constants.S4_SUBTITLE}
                    </Text>
                </Box>

            </Box>
        </>
    )
}

export default Section4;