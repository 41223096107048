import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { 
    Footer as FooterGrommet,
    Button,
    Text,
    Image,
    Box,
    Anchor
} from 'grommet';
import * as Icons from 'grommet-icons';
import { constants } from '../types/constants';

function Footer() {

    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
    const isTablet = useMediaQuery({ query: `(max-width: 1024px)` });

    return(
        <FooterGrommet background="brand" pad="medium" >
            <Box 
                direction="column" 
                width="275px" 
                pad="small"
                style={{
                    marginLeft: (isMobile || isTablet) ? '0px' : '250px',
                }}
            >
                <Image 
                    fit="contain"
                    src="/assets/logo.svg"
                    style={{
                        marginLeft: '10px',
                        marginTop: '5px',
                        marginBottom: '25px',
                    }}
                />

                <Text size="small">{constants.F_LABEL1}</Text>
                <Text size="small">{constants.F_LABEL2}</Text>
            </Box>

            <Box 
                alignContent="end" 
                justify='end' 
                direction="row" 
                width="275px" 
                pad="small" 
                margin={"large"}
                style={{
                    marginRight: (isMobile || isTablet) ? '0px' : '250px',
                }}
            >
                <Anchor href='https://www.linkedin.com/company/educactiva-learning-consulting/' target='_blank' icon={<Icons.Linkedin />} />
                <Anchor href='https://www.facebook.com/profile.php?id=100093143306159' target='_blank' icon={<Icons.Facebook />} />
                <Anchor href='https://www.instagram.com/educactivaconsulting/' target='_blank' icon={<Icons.Instagram />} />

                {(isMobile || isTablet) &&
                    <Anchor 
                        href='https://api.whatsapp.com/send?phone=50686869370' 
                        target='_blank'
                        style={{
                            //justifyContent: 'center',
                            alignContent: 'center'
                        }} 
                        icon={
                            <Image 
                                //fit="contain"
                                src="/assets/whatsapp.svg"
                                style={{
                                    cursor: 'pointer',
                                    paddingLeft: '2px',
                                    width: '24px'
                                }}
                            />
                        }
                    />
                }
            </Box>
        </FooterGrommet>
    )
}

export default Footer;