import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { 
    Box, 
    Header as HeaderGrommet,
    Menu,
    Anchor,
    Nav,
    Image,
    Button
} from 'grommet';
import * as Icons from 'grommet-icons';
import { constants } from '../types/constants';

function Header() {

    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

    return(
        <HeaderGrommet background="brand" id="header">

            <Box width="275px" pad="small">
                <Image 
                    fit="contain"
                    src="/assets/logo.svg"
                    style={{
                        marginLeft: '10px',
                        marginTop: '5px',
                        marginBottom: '5px',
                    }}
                />
            </Box>

            {isMobile ?
                <Menu
                    //label="Menu"
                    dropBackground="white"
                    size="xlarge"
                    margin={"medium"}
                    justifyContent="between"
                    icon={<Icons.Menu/>}
                    items={[
                        { label: <Button href="#servicios" label={constants.H_LABEL1} plain /> },
                        { label: <Button href="#beneficios" label={constants.H_LABEL2}  plain /> },
                        { label: <Button href="#contactanos" label={constants.H_LABEL3} plain /> },
                    ]}
                />
                :
                <Nav direction="row" pad="small" margin="small">
                    <Anchor href="#servicios" label={constants.H_LABEL1} />
                    <Anchor href="#beneficios" label={constants.H_LABEL2} />
                    <Anchor href="#contactanos" label={constants.H_LABEL3} />
                </Nav>
            }


        </HeaderGrommet>
    )
}

export default Header;