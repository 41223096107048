import React from 'react';
import {  
    Box,
    Text
} from 'grommet';
import CustomCard from './shared/CustomCard';
import { useMediaQuery } from 'react-responsive';
import { constants } from '../types/constants';
import * as Icons from 'grommet-icons';

function Section6() {

    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
    const isTablet = useMediaQuery({ query: `(max-width: 1024px)` });

    return(
        <>
            <Box 
                background="white"
                pad={isMobile || isTablet ? "small" : "xlarge"}
                animation={"fadeIn"}
                alignContent='center'
                justify='center'
            >
            
                <Box 
                    align="center"
                    style={{
                        //paddingBottom: '5%',
                        paddingLeft: '20%',
                        paddingRight: '20%'
                    }}
                    animation={"fadeIn"}
                >
                    <Text
                        alignSelf="center"
                        textAlign="center"
                        size="calc(20px + (40 - 20) * ((100vw - 300px) / (1600 - 300)))"
                        margin="medium"
                        style={{
                            marginLeft: `${!(isMobile || isTablet) ? '24px' : '0'}`,
                            marginRight: `${!(isMobile || isTablet) ? '24px' : '0'}`
                        }}
                        weight="bold"
                        color={"#43484d"}
                    >
                        {constants.S6_TITLE}
                    </Text>

                    <Text
                        alignSelf="center"
                        textAlign="center"
                        color={"#43484d"}
                    >
                        {constants.S6_SUBTITLE}
                    </Text>
                </Box>

                <Box 
                    direction={isMobile || isTablet ? undefined : "row"}
                    pad="large"
                    animation={"fadeIn"}
                    alignContent='center'
                    justify='center'
                    gap='large'
                >

                    <CustomCard
                        headerText = {constants.S6_C1_TITLE}
                        contentText = {constants.S6_C1_SUBTITLE}
                        minHeight="300px"
                        background = "#19376D"
                        fontColor="white"
                        animate={true}
                        icon={<Icons.Money size={isMobile ? "medium" : "large"}/>}
                    />

                    <CustomCard
                        headerText = {constants.S6_C2_TITLE}
                        contentText = {constants.S6_C2_SUBTITLE}
                        minHeight="300px"
                        background = "#19376D"
                        fontColor="white"
                        animate={true}
                        icon={<Icons.Book size={isMobile ? "medium" : "large"}/>}
                    />

                    <CustomCard
                        headerText = {constants.S6_C3_TITLE}
                        contentText = {constants.S6_C3_SUBTITLE}
                        minHeight="300px"
                        background = "#19376D"
                        fontColor="white"
                        animate={true}
                        icon={<Icons.Group size={isMobile ? "medium" : "large"}/>}
                    />

                </Box>

                <Box 
                    direction={isMobile || isTablet ? undefined : "row"}
                    pad="large"
                    animation={"fadeIn"}
                    alignContent='center'
                    justify='center'
                    gap='large'
                >

                    <CustomCard
                        headerText = {constants.S6_C4_TITLE}
                        contentText = {constants.S6_C4_SUBTITLE}
                        minHeight="300px"
                        background = "#19376D"
                        fontColor="white"
                        animate={true}
                        icon={<Icons.Clock size={isMobile ? "medium" : "large"}/>}
                    />

                    <CustomCard
                        headerText = {constants.S6_C5_TITLE}
                        contentText = {constants.S6_C5_SUBTITLE}
                        minHeight="300px"
                        background = "#19376D"
                        fontColor="white"
                        animate={true}
                        icon={<Icons.Ascend size={isMobile ? "medium" : "large"}/>}
                    />

                    <CustomCard
                        headerText = {constants.S6_C6_TITLE}
                        contentText = {constants.S6_C6_SUBTITLE}
                        minHeight="300px"
                        background = "#19376D"
                        fontColor="white"
                        animate={true}
                        icon={<Icons.Catalog size={isMobile ? "medium" : "large"}/>}
                    />

                </Box>

            </Box>
        </>
    )
}

export default Section6;