import React from 'react';
import { 
    Grommet, 
    grommet, 
} from 'grommet';
import { deepMerge } from 'grommet/utils';
import { theme } from './website-theme';
import Header from './components/Header';
import Section1 from './components/Section1';
import Section2 from './components/Section2';
import Section3 from './components/Section3';
import Section4 from './components/Section4';
import Section5 from './components/Section5'
import Section6 from './components/Section6';
import Section7 from './components/Section7';
import Section8 from './components/Section8';
import Footer from './components/Footer';
import ContactIcon from './components/ContactIcon';
import { useMediaQuery } from 'react-responsive';

const _theme = deepMerge(grommet, theme);

function App() {

    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
    const isTablet = useMediaQuery({ query: `(max-width: 1024px)` });

    return (
        <Grommet theme={_theme} full={true} themeMode={false ? "dark" : "light"} className='scrollbar custom-scrollbar'>

            <Header />

            <Section1  />

            <Section2 />

            <div id="empresas">
                <Section3 />
            </div>
            
            <div id="profesionales">
                <Section4 />
            </div>

            <div id="servicios">
                <Section5 />
            </div>

            <div id="beneficios">
                <Section6 />
            </div>

            {/*
                <Section7 />
            */}

            <div id="contactanos">
                <Section8 />
            </div>

            {!isMobile && !isTablet &&
                <ContactIcon />
            }

            <Footer />

        </Grommet>
    );
}
export default App;