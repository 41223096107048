import React from 'react';
import {  
    Box,
    Text
} from 'grommet';
import CustomCard from './shared/CustomCard';
import { useMediaQuery } from 'react-responsive';
import { constants } from '../types/constants';
import * as Icons from 'grommet-icons';

function Section5() {

    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
    const isTablet = useMediaQuery({ query: `(max-width: 1024px)` });

    const getCardSizes = () =>{
        if(isMobile){
            return "100%"
        }else if(isTablet) {
            return "150%"
        } else {
            return "300px"
        }
    }

    return(
        <>
            <Box 
                background="grey-1"
                pad={isMobile || isTablet ? "small" : "large"}
                animation={"fadeIn"}
                alignContent='center'
                justify='center'
            >

                <Text
                    //textAlign={isMobile || isTablet ? "center" : "end"}
                    textAlign={"center"}
                    size="calc(20px + (34 - 20) * ((100vw - 300px) / (1600 - 300)))"
                    margin="medium"
                    weight="bold"
                    color={"#43484d"}
                >
                    {constants.S5_TITLE}
                </Text>

                <Box 
                    direction={isMobile || isTablet ? undefined : "row"}
                    pad="large"
                    animation={"fadeIn"}
                    alignContent='center'
                    justify='center'
                    gap='large'
                >

                    <CustomCard
                        headerText = {constants.S5_C1_TITLE}
                        contentText = {constants.S5_C1_SUBTITLE}
                        minHeight={ getCardSizes() }
                        background = "white"
                        animate={true}
                        icon={<Icons.Article size={isMobile ? "medium" : "large"}/>}
                    />

                    <CustomCard
                        headerText = {constants.S5_C2_TITLE}
                        contentText = {constants.S5_C2_SUBTITLE}
                        minHeight={ getCardSizes() }
                        background = "white"
                        animate={true}
                        icon={<Icons.AssistListening size={isMobile ? "medium" : "large"}/>}
                    />

                    <CustomCard
                        headerText = {constants.S5_C3_TITLE}
                        contentText = {constants.S5_C3_SUBTITLE}
                        minHeight={ getCardSizes() }
                        background = "white"
                        animate={true}
                        icon={<Icons.Catalog size={isMobile ? "medium" : "large"}/>}
                    />

                </Box>

            </Box>
        </>
    )
}

export default Section5;