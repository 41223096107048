import React from 'react';
import {  
    Box,
    Text,
    Card,
    CardHeader,
    CardBody,
} from 'grommet';
import { useMediaQuery } from 'react-responsive';
import { motion } from 'framer-motion';

function CustomCard(props: any) {

    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
    const isTablet = useMediaQuery({ query: `(max-width: 1024px)` });

    return(
        <>
        {props.animate ?
            <motion.span
                whileHover={{ scale: 1.05 }}
                //whileTap={{ scale: 0.9 }}
            >
                <Box
                    style={{
                        maxWidth: isMobile || isTablet ? undefined : '505px'
                    }}
                >
                    <Card background={props.background} style={{minHeight: isMobile ? undefined : props.minHeight, maxHeight: isMobile ? undefined : props.minHeight}}>
                        <CardHeader direction="column" pad="medium" align="center" alignContent='center' justify='center'>
                            <Box>
                                {props.icon}
                            </Box>
                            <Text
                                //size="calc(20px + (25 - 20) * ((100vw - 300px) / (1600 - 300)))"
                                size={isMobile ? "20px" : "22px"}
                                weight="bold"
                                alignSelf='center'
                                color={props.fontColor ? props.fontColor : "#43484d"}
                            >
                                {props.headerText}
                            </Text>
                        </CardHeader>
                        <CardBody pad="medium" alignContent='center' justify='center'>
                            <Text
                                size={isMobile ? "17px" : "20px"}
                                textAlign="center"
                                color={props.fontColor ? props.fontColor : "#43484d"}
                            >
                                {props.contentText}
                            </Text>
                        </CardBody>
                    </Card>
                </Box>
            </motion.span>
        :
            <Card width="large" background={props.background} style={{minHeight:props.minHeight}}>
                <CardHeader pad="medium">
                    <Text
                        //textAlign={"center"}
                        size="calc(20px + (25 - 20) * ((100vw - 300px) / (1600 - 300)))"
                        weight="bold"
                        color={props.fontColor ? props.fontColor : "#43484d"}
                    >
                        {props.headerText}
                    </Text>
                </CardHeader>
                <CardBody pad="medium">
                    <Text
                        textAlign={isMobile || isTablet ? "center" : "start"}
                        color={props.fontColor ? props.fontColor : "#43484d"}
                    >
                        {props.contentText}
                    </Text>
                </CardBody>
            </Card>
        }
        </>
    )
}

export default CustomCard;