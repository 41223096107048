import React from 'react';
import {  
    Box,
    Image,
    Text
} from 'grommet'
import { useMediaQuery } from 'react-responsive';
import { constants } from '../types/constants';
import CustomImage from './shared/CustomImage';

function Section3() {

    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
    const isTablet = useMediaQuery({ query: `(max-width: 1024px)` });

    const getJSXElement = (): JSX.Element =>{
        const textSection = (
            <Box 
                width="large" 
                pad="small" 
                alignSelf="center"
            >
                <Text
                    //textAlign={isMobile || isTablet ? "center" : "end"}
                    textAlign={"center"}
                    size="calc(20px + (34 - 20) * ((100vw - 300px) / (1600 - 300)))"
                    margin="medium"
                    weight="bold"
                    color={"#43484d"}
                >
                    {constants.S3_TITLE}
                </Text>

                <Text
                    //textAlign={isMobile || isTablet ? "center" : "end"}
                    textAlign={"center"}
                    color={"#43484d"}
                >
                    {constants.S3_SUBTITLE}
                </Text>
            </Box>
        );
        const imageSection = (
            <CustomImage 
                href="assets/meeting.webp"
            />
        );

        if(isMobile || isTablet) {
            return (
                <>
                    {imageSection}
                    {textSection}
                </>
            );
        } else {
            return (
                <>
                    {textSection}
                    {imageSection}
                </>
            );
            
        }
    }

    return(
        <>
            <Box 
                direction={isMobile || isTablet ? undefined : "row"}
                background="grey-1"
                pad={isMobile || isTablet ? "small" : "large"}
                animation={"fadeIn"}
            >

                {getJSXElement()}

            </Box>
        </>
    )
}

export default Section3;