export const constants = Object.freeze({
    /* HEADER */
    H_LABEL1: 'Nuestros servicios',
    H_LABEL2: 'Beneficios',
    H_LABEL3: 'Contáctanos',
    
    /* SECTION 1 */
    S1_TITLE: 'Su empresa es única, sus capacitaciones también',
    S1_SUBTITLE: 'Diseñamos estrategias para la gestión del conocimiento, formamos a sus colaboradores en habilidades para capacitar personal y le asesoramos en cualquier etapa de coaching y entrenamiento empresarial.',
    S1_LEFT_BUTTON: 'Empresas',
    S1_RIGHT_BUTTON: 'Profesionales',

    /* SECTION 2 */
    S2_TITLE: 'Acompañamos a las empresas y profesionales a crear entornos de aprendizaje sanos, eficientes y efectivos',
    S2_SUBTITLE: 'Entendemos la importancia de la planificación de capacitaciones y desarrollar profesionales con las habilidades para transmitir conocimiento. Combinamos la técnica de diseño curricular, la formación de colaboradores en competencias para la gestión de conocimiento y la asesoría continua.',

    /* SECTION 3 */
    S3_TITLE: 'Empresas',
    S3_SUBTITLE: 'Somos su aliado en la elaboración de estrategias de capacitación para procesos, productos y colaboradores. La gestión de conocimiento dentro de las organizaciones es clave para asegurar la estandarización y agilidad, incrementar la productividad y utilidades, la motivación y obtener índices de rotación de personal sanos.',

    /* SECTION 4 */
    S4_TITLE: 'Profesionales',
    S4_SUBTITLE: 'Le acompañamos en sus diferentes proyectos profesionales, desde el diseño curricular de un curso para promocionar sus productos, técnicas de capacitación de colaboradores dentro de una empresa o si está relacionado a cualquier ámbito de la enseñanza para adultos (Docente en universidades, Institutos, colegios técnicos, ONGs).',

    /* SECTION 5 */
    S5_TITLE: 'Nuestros servicios',
    S5_C1_TITLE: 'Consultoría',
    S5_C1_SUBTITLE: 'Diseño de la estrategía de capacitación de puestos, productos y personal.',
    S5_C2_TITLE: 'Formaciones',
    S5_C2_SUBTITLE: 'Formamos a colaboradores, líderes y profesionales independientes en diseño, habilidades y competencias de capacitación de personal.',
    S5_C3_TITLE: 'Asesoría',
    S5_C3_SUBTITLE: 'Sesiones 1:1 de trabajo para abarcar oportunidades de mejora en cualquier etapa del proceso de coaching y entrenamiento empresarial.',

    /* SECTION 6 */
    S6_TITLE: 'Beneficios al contar con nuestros servicios y formaciones',
    S6_SUBTITLE: '',
    S6_C1_TITLE: 'Utilidades, costos y tiempos',
    S6_C1_SUBTITLE: 'Contar con planes definidos y colaboradores formados en habilidades para la capacitación propician que la estructura de entrenamiento sea eficaz y eficiente.',
    S6_C2_TITLE: 'Estrategias para capacitaciones',
    S6_C2_SUBTITLE: `Cada aspecto de nuestras empresas fue, es y será parte de un proceso de aprendizaje. Los planes de capacitación reducen la fuga de información.`,
    S6_C3_TITLE: 'Motivación',
    S6_C3_SUBTITLE: 'Clientes, colaboradores, lideres son parte de una experiencia de aprendizaje, la motivación es clave para asegurar su permanencia con nuestras empresas.',
    S6_C4_TITLE: 'Rotación de personal',
    S6_C4_SUBTITLE: 'Un buen programa de retención de personal inicia con una buena capacitación. La experiencia de aprendizaje del colaborador está asociada a su permanencia en una empresa.',
    S6_C5_TITLE: 'Tiempos de respuesta',
    S6_C5_SUBTITLE: 'Asignar la creación de planes de capacitación a expertos en diseño curricular permite asegurar un proceso claro con un plan de ejecución definido.',
    S6_C6_TITLE: 'Documentación',
    S6_C6_SUBTITLE: 'Documentar los procesos de capacitación dan seguridad a la organización y dan paso a elementos más específicos como manuales de procedimiento.',

    /* SECTION 7 */
    S7_TITLE: 'Nuestros servicios han sido de ayuda para las siguientes empresas',

    /* SECTION 8 */
    S8_FORM_TITLE: '¡A un click de distancia!',
    S8_FORM_LABEL1: 'Nombre',
    S8_FORM_LABEL2: 'Correo electrónico',
    S8_FORM_LABEL3: 'Sus comentarios',
    S8_FORM_LEFT_BUTTON: 'Enviar',
    S8_FORM_RIGHT_BUTTON: 'Limpiar',

    /* FOOTER */
    F_LABEL1: `©${(new Date()).getFullYear()} EducActiva Learning Consulting S.R.L.`,
    F_LABEL2: 'All rights reserved.',
});