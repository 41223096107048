import React from 'react';
import { useMediaQuery } from 'react-responsive';
import {
    Tip,
    Image,
    Box
} from 'grommet';

function ContactIcon() {

    const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
    const isTablet = useMediaQuery({ query: `(max-width: 1024px)` });

    return(
        
        <Box 
            width="75px"
        >
            <Tip content="¡Contáctanos por whatsapp!">
                <Image 
                    fit="contain"
                    src="/assets/whatsapp.png"
                    className='whatsapp-icon'
                    onClick={()=>{window.open('https://api.whatsapp.com/send?phone=50686869370', '_blank');}}
                />
            </Tip>
        </Box>
    )
}

export default ContactIcon;