export const theme = {
    global: {
        colors: {

            /* BEGIN: Color Palette Definition */
            ruby: {
                dark: "green",
                light: "#"
            },
            "ruby!": "#0B2447",
            gold: {
                dark: "white",
                light: "#576CBC"
            },
            "gold!": "#F9B644",
            amethyst: {
                dark: "#9B59B6",
                light: "#C39BD3"
            },
            "amethyst!": "#AF7AC5",
            "grey-1": "#F5F7FA",
            "grey-2": "#CECCC6",
            "grey-3": "#737069",
            "grey-4": "#52504C",
            /* END: Color Palette Definition */

            /* BEGIN: Mapping Colors to Grommet Namespaces */
            background: {
                dark: "grey-4",
                light: "grey-1"
            },
            "background-back": {
                dark: "grey-4",
                light: "grey-1"
            },
            "background-front": {
                dark: "grey-3",
                light: "grey-2"
            },
            brand: "ruby!",
            control: {
                dark: "brand",
                light: "brand"
            },
            input: {
                background: "blue"
            },
            text: {
                dark: "white",
                light: "#576CBC"
            }
        },

        focus: {
            border: {
                color: "gold"
            }
        },
        /* END: Mapping Colors to Grommet Namespaces */

        font: {
            family: "Raleway"
        },
    

    },

    button: {
        primary: {
            color: '#576CBC',
        },
        secondary: {
            background:{
                color: 'rgba(0,0,0,0.5);',
            },
            theme: "light",
        },
        border:{
            color: '#576CBC',
            radius: '1px !important',
        },
        size:{
            large:{
                border:{
                    radius: "5px"
                }
            },
            small:{
                border:{
                    radius: "5px"
                }
            },
            medium:{
                border:{
                    radius: "5px"
                }
            }
        },
    },

    /* BEGIN: Mapping Colors to Components */
    anchor: {
        color: {
            dark: "gold",
            light: "amethyst!"
        }
    },
    /* END: Mapping Colors to Components */
};